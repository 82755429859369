<template>
  <div class="monitors">
    <h2 class="ml-2">Maintenance</h2>

    <div class="card mb-3">
      <div class="card-body d-flex">
        <div class="mr-3">
          Maintenance allows you to pause monitors for a certain period of time to prevent them from impacting uptime
          statistics.
          Additionally, you can display incident notes on your status pages and provide updates to your users.
        </div>
        <router-link :to="createRoute">
          <base-button color="primary"
                       class="addNew"
                       :icon="'IconAdd'"
          >
            Schedule maintenance
          </base-button>
        </router-link>
      </div>
    </div>

    <maintenance-list :status-page="statusPage"/>
  </div>
</template>

<script>
import MaintenanceList from '@/components/StatusPage/Maintenance/MaintenanceList.vue'
import BaseButton from '@/components/Base/BaseButton.vue'

export default {
  components: { BaseButton, MaintenanceList },

  metaInfo: {
    title: 'Maintenance'
  },

  props: {
    statusPage: {
      required: true
    }
  },

  computed: {
    createRoute () {
      return {
        name: 'statusPages.single.maintenance.create'
      }
    }
  }
}
</script>
